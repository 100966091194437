import { useLocation } from 'react-router-dom';

export const useReadUserIdFromQueryParams = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const userIdQueryParam = searchParams.get('userId') ?? undefined;
  const decodedUserIdQueryParam =
    userIdQueryParam && decodeURIComponent(userIdQueryParam);

  return {
    userId: decodedUserIdQueryParam,
  };
};
