import { AvailabilityEntry } from '../../api/types';

export const addDays = (date: Date, days: number) =>
  new Date(date.getTime() + days * 24 * 60 * 60 * 1000);

export const getWeekStart = (date: Date) => {
  const newDate = new Date(date);
  const dayOfWeek = newDate.getDay(); // 0 (Sunday) to 6 (Saturday)

  // Adjust to Monday as the first day (if today is Sunday, go back 6 days)
  const diff = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;

  newDate.setDate(newDate.getDate() + diff);
  return newDate;
};

const formatDate = (date: Date) => {
  const day = date.getDate();
  const formattedDay = day < 10 ? `0${day}` : day;
  const month = date.getMonth() + 1;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const year = date.getFullYear();
  return `${year}-${formattedMonth}-${formattedDay}`; // YYYY-MM-DD
};

export const formatTime = (isoString: string, timeZone: string | undefined) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone,
  });
};

export const formatWeekRange = (startDate: Date) => {
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);
  return `${startDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  })} - ${endDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  })}`;
};

// given the array of all the availability entries,
// this function returns the time slots for the specified date
export const getDateAvailability = (
  availability: AvailabilityEntry[],
  date: Date
) => {
  const dateString = formatDate(date);
  const availableTimesForDate = availability.find(
    (entry) => entry.date === dateString
  );

  if (!availableTimesForDate) return [];

  return availableTimesForDate.times;
};

export const getTimeZoneShortName = (timeZone: string | undefined) => {
  if (!timeZone) return null;

  const opts: Intl.DateTimeFormatOptions = {
    timeZoneName: 'short',
    timeZone,
  };
  return Intl.DateTimeFormat('en-EN', opts).format(Date.now()).split(',')[1];
};

export const exceedTimeBound = (startingDate: Date, maxWeeksCount: number) => {
  const nextWeek = addDays(startingDate, 7);
  const timeBound = addDays(new Date(), 7 * maxWeeksCount);
  return nextWeek.getTime() >= timeBound.getTime();
};

export const convertLocalBrowserDateToUserDate = (localBrowserDate: Date) => {
  const localTimezoneDate = new Date(
    localBrowserDate.toLocaleString('en-US', {
      timeZone: 'UTC',
    })
  );
  const diff = localBrowserDate.getTime() - localTimezoneDate.getTime();
  return new Date(localBrowserDate.getTime() - diff);
};
