import { MeetingTypeId } from '../../api/types';

export enum SurveyType {
  visitGoals = 'visit-goals',
}

type SurveyData<T> = T extends SurveyType.visitGoals
  ? {
      goals: string;
      appointment_type: MeetingTypeId;
    }
  : any;

export type FeedbackPayload<T> = {
  survey: SurveyData<T>;
  survey_type: T extends SurveyType ? SurveyType : any;
  user_id: string;
};
