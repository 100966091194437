import { HStack, Image, Text, VStack } from 'native-base';
import { useMemo } from 'react';

const star = '../../star.png';
const verifiedReview = '../../verified_review.png';
const logoDark = '../../FA__ayble_health_Logo_Isabelline.png';

type Props = {
  content: React.ReactNode;
  name: string;
  numStars: number;
};

export const ReviewCard = ({ content, name, numStars }: Props) => {
  const starSource = useMemo(() => ({ uri: star }), []);
  const reviewSource = useMemo(() => ({ uri: verifiedReview }), []);
  const logoSource = useMemo(() => ({ uri: logoDark }), []);

  return (
    <VStack
      bg="white"
      borderColor={'muted.200'}
      borderWidth={1}
      height={'310px'}
      justifyContent={'space-between'}
      p={6}
      rounded="2xl"
      shadow={'9'}
      width={'310px'}
    >
      <HStack space={1}>
        {Array.from({ length: numStars }).map((_, index) => {
          return (
            <Image
              key={`star-${index}`}
              source={starSource}
              alt="rating star"
              w={'16px'}
              h={'16px'}
            />
          );
        })}
      </HStack>

      <HStack space={3} justifyContent={'space-evenly'}>
        <VStack justifyContent="space-evenly" flexShrink={1}>
          {content}
        </VStack>
      </HStack>

      <HStack alignItems={'center'} justifyContent={'space-between'}>
        <VStack>
          <Text mb={1} fontSize={'sm'} fontWeight={'normal'}>
            {name}
          </Text>

          <Image
            alt="verified review"
            h={'12px'}
            w={'100px'}
            source={reviewSource}
          />
        </VStack>

        <VStack>
          <Image
            alt="Ayble Health logo"
            h={'32px'}
            source={logoSource}
            w={'64px'}
          />
        </VStack>
      </HStack>
    </VStack>
  );
};
