import styled from '@emotion/styled';
import { ITheme } from 'native-base';

export const GradientBox = styled('div')<{ flex?: boolean; theme?: ITheme }>`
  background-image: linear-gradient(
    to bottom right,
    #ffffff,
    #f7f5ed,
    #f7f5ed,
    #ebf5ff,
    #ebf5ff
  );
  display: ${(p) => (p.flex ? 'flex' : undefined)};
  flex: ${(p) => (p.flex ? 1 : undefined)};
`;

export const MobileGradientBox = styled('div')<{
  flex?: boolean;
  theme?: ITheme;
}>`
  background-image: linear-gradient(to bottom, #ebf5ff, #ebf5ff, #f7f5ed);
  display: ${(p) => (p.flex ? 'flex' : undefined)};
  flex: ${(p) => (p.flex ? 1 : undefined)};
  width: 100%;
`;
