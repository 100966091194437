import { useState } from 'react';
import {
  Box,
  Button,
  Heading,
  VStack,
  Text,
  useBreakpointValue,
} from 'native-base';

import { useFormData } from '../../utils/formReducer.js';
import { useGoogleTagManager } from '../../hooks/useGoogleTagManager';
import SignUpInfo from './SignUpInfo';
import {
  TRACKING_ONBOARDING_COMPLETED,
  TRACKING_PURCHASE_LOCAL_STORAGE_KEY,
} from './constants';
import TOSInput from './TOSInput';
import { LogosPageScaffolding } from '../LogosPageScaffolding';

// 18_last_step_page
const EndOfFlow = ({ handleSubmit, apiError }) => {
  const { state: formData, dispatch } = useFormData();
  const [tosChecked, setTosChecked] = useState(false);

  const { trackEventInGoogleTagManager } = useGoogleTagManager();
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });

  const handleNext = () => {
    // set this flag to track the purchase, we will clear it once
    // we track it in the thank you page
    localStorage.setItem(TRACKING_PURCHASE_LOCAL_STORAGE_KEY, true);

    trackEventInGoogleTagManager(TRACKING_ONBOARDING_COMPLETED);

    handleSubmit();
  };

  const isFormValid = (formData) => {
    if (
      !formData.firstName ||
      !/[a-zA-Z]/g.test(formData.firstName) ||
      !formData.lastName ||
      !/[a-zA-Z]/g.test(formData.lastName) ||
      !formData.email ||
      formData.email.indexOf('@') < 0 ||
      !formData.state ||
      (!formData.selected_affiliate && formData.affiliate_name) ||
      !tosChecked
    ) {
      return false;
    }

    return true;
  };

  return (
    <LogosPageScaffolding>
      <VStack flex={1} pt={8} px={isMobile ? '15px' : '80px'}>
        <Heading
          fontSize={'3xl'}
          mb={4}
          textAlign={'left'}
          lineHeight={'35px'}
          maxW={isMobile ? '100%' : '70%'}
        >
          You’re almost there — let’s check if your insurance or employer covers
          Ayble Health.
        </Heading>

        <Text fontSize={'lg'}>
          Don’t worry! You can also pay for Ayble yourself if you prefer!
        </Text>

        {apiError && (
          <Box
            mt={4}
            maxW={isMobile ? '100%' : '70%'}
            borderColor={'red.500'}
            borderWidth={1}
            backgroundColor={'red.100'}
            p={3}
          >
            <Text fontWeight="bold" color={'red.500'}>
              {apiError}
            </Text>
          </Box>
        )}

        <SignUpInfo
          formData={formData}
          dispatch={dispatch}
          isMobile={isMobile}
        />

        <Box my={2} zIndex={-1}>
          <TOSInput
            tosChecked={tosChecked}
            handleTosChange={() => setTosChecked((prevState) => !prevState)}
          />
        </Box>

        <Button
          alignSelf={isMobile ? 'center' : 'flex-start'}
          isDisabled={!isFormValid(formData)}
          ml={2}
          mt={isMobile ? 5 : 10}
          mb={5}
          block
          maxW={'240px'}
          size={'md'}
          onPress={handleNext}
          zIndex={-1}
        >
          Next
        </Button>
      </VStack>
    </LogosPageScaffolding>
  );
};

export default EndOfFlow;
