import React from 'react';
import './App.css';
import { NativeBaseProvider } from 'native-base';
import { PostHogProvider } from 'posthog-js/react';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthProvider } from './hooks';
import { theme } from './theme/theme';
import { FormDataProvider } from './utils/formReducer';
import { Root } from './Root';
import { ModalsProvider } from './hooks/useModalsContext';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'react-virtualized/styles.css';

const {
  REACT_APP_PUBLIC_POSTHOG_HOST = 'https://us.i.posthog.com',
  REACT_APP_PUBLIC_POSTHOG_KEY = 'staging_fake_key',
  REACT_APP_GTM_CONTAINER_ID = 'GTM-P273C4V',
} = process.env;

const postHogOptions = { api_host: REACT_APP_PUBLIC_POSTHOG_HOST };
const gtmParams = { id: REACT_APP_GTM_CONTAINER_ID };
const queryClient = new QueryClient();

function App() {
  return (
    <GTMProvider state={gtmParams}>
      <PostHogProvider
        apiKey={REACT_APP_PUBLIC_POSTHOG_KEY}
        options={postHogOptions}
      >
        <NativeBaseProvider theme={theme}>
          <EmotionThemeProvider theme={theme}>
            <ModalsProvider>
              <AuthProvider>
                <QueryClientProvider client={queryClient}>
                  <ReactQueryDevtools initialIsOpen={false} />
                  <FormDataProvider>
                    <Root />
                  </FormDataProvider>
                </QueryClientProvider>
              </AuthProvider>
            </ModalsProvider>
          </EmotionThemeProvider>
        </NativeBaseProvider>
      </PostHogProvider>
    </GTMProvider>
  );
}

export default App;
