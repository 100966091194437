import {
  Box,
  Button,
  Heading,
  HStack,
  SmallCloseIcon,
  Spinner,
  Text,
  VStack,
} from 'native-base';
import { DateTimePicker } from './DateTimePicker';
import HeaderNav from '../HeaderNav/HeaderNav';
import styled from '@emotion/styled';
import { NAV_HEADER_HEIGHT_PX } from '../../constants/misc';
import { Navigate } from 'react-router-dom';
import { useScheduleAppointmentPage } from './useScheduleAppointmentPage';
import { IconButton } from '../Form/IconButton';
import { GradientBox, MobileGradientBox } from '../ui/style';

const ACTION_BUTTONS_HEIGHT_PX = 96;
const ACTION_BUTTONS_PADDING_PX = 48;
const CONTENT_MAX_HEIGHT_PX =
  NAV_HEADER_HEIGHT_PX + ACTION_BUTTONS_HEIGHT_PX + ACTION_BUTTONS_PADDING_PX;

const Column = styled('div')<{ subHeaderHeight: number }>`
  display: flex;
  flex: 1;
  max-height: ${(p) => {
    const heightToSubtract = CONTENT_MAX_HEIGHT_PX + p.subHeaderHeight;
    return `calc(100vh - ${heightToSubtract}px)`;
  }};
  overflow: auto;
  width: 100%;
`;

export const ScheduleAppointment = () => {
  const {
    availabilityResponse,
    availabilityType,
    createAppointmentError,
    dismissCreateAppointmentError,
    firstAvailableDay,
    isCreatingNewAppointment,
    isFetchingAvailability,
    isMobile,
    onCreateMeeting,
    onSelectDate,
    onSelectTime,
    onSkip,
    selectedDate,
    selectedTime,
    subHeaderHeight,
    subHeaderRef,
    userIdQueryParam,
    timezone,
  } = useScheduleAppointmentPage();

  if (!userIdQueryParam) return <Navigate to={'/'} replace />;

  if (isFetchingAvailability) return <Spinner size={'sm'} />;

  const GradientBg = isMobile ? MobileGradientBox : GradientBox;
  const subTitle =
    availabilityType === 'coach'
      ? 'Initial Coach Call'
      : 'Initial Provider Consultation';

  return (
    <VStack h={'100vh'}>
      <HeaderNav hideBack />

      <GradientBg flex>
        <VStack alignItems={'center'} flex={1}>
          <Box ref={subHeaderRef} alignItems={'center'}>
            <Heading color={'secondary.500'} fontWeight={'normal'} pt={6}>
              Schedule your first visit
            </Heading>

            <Text color={'secondary.200'} fontWeight={'normal'} pt={3}>
              {subTitle}
            </Text>
            <Text color={'secondary.200'} fontWeight={'normal'}>
              Virtual Video Visit
            </Text>
            <Text color={'secondary.200'} fontWeight={'normal'} pb={4}>
              45 minutes
            </Text>
          </Box>

          <VStack flex={1} maxW={'600px'} w={'100%'} p={6}>
            {createAppointmentError && (
              <HStack
                alignItems={'center'}
                justifyContent={'space-between'}
                borderColor={'red.500'}
                borderWidth={1}
                backgroundColor={'red.100'}
                mb={6}
                p={3}
              >
                <Text fontWeight="bold" color={'red.500'}>
                  {createAppointmentError}
                </Text>

                <IconButton onClick={dismissCreateAppointmentError}>
                  <SmallCloseIcon />
                </IconButton>
              </HStack>
            )}

            <Column subHeaderHeight={subHeaderHeight}>
              <DateTimePicker
                availability={availabilityResponse?.availability ?? []}
                firstAvailableDay={firstAvailableDay}
                onSelectDate={onSelectDate}
                onSelectTime={onSelectTime}
                selectedDate={selectedDate}
                selectedTime={selectedTime}
                timezone={timezone}
              />
            </Column>
          </VStack>

          {/* Action Buttons */}
          <Box
            w={'100%'}
            position={'absolute'}
            bottom={0}
            px={6}
            height={`${ACTION_BUTTONS_HEIGHT_PX}px`}
          >
            <Button
              alignSelf={'center'}
              bgColor={'secondary.500'}
              isDisabled={!selectedTime}
              isLoading={isCreatingNewAppointment}
              onPress={onCreateMeeting}
              pt={4}
              w={isMobile ? '100%' : '400px'}
            >
              <Text color={'white'} fontWeight={'bold'}>
                Book Appointment
              </Text>
            </Button>

            <Button
              alignSelf={'center'}
              bgColor={'transparent'}
              onPress={onSkip}
              variant={'ghost'}
              w={isMobile ? '100%' : '400px'}
            >
              <Text color={'secondary.500'} fontWeight={'bold'}>
                Skip for now
              </Text>
            </Button>
          </Box>
        </VStack>
      </GradientBg>
    </VStack>
  );
};
