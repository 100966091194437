import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Card } from 'native-base';

import { PublicRoute } from './components/PublicRoute';
import { ProtectedRoute } from './components/ProtectedRoute';
import Form from './components/OnboardingForm/Form';
import ThankYou from './components/ThankYouView/ThankYou';
import { PlansPage } from './components/PlansView/PlansPage';
import Eligibility from './components/EligibilityView/Eligibility';
import { useAuth } from './hooks';
import { useModal } from './hooks/useModal';
import ContentPreview from './components/ContentPreview/ContentPreview';
import UnsubscribedLanding from './components/UnsubscribedLanding/UnsubscribedLanding';
import PhoneNumber from './components/PhoneNumberView/PhoneNumber';
import { LoginRoute } from './components/LandingPage/LoginRoute';
import { ScheduleAppointment } from './components/ScheduleAppointmentView/ScheduleAppointment';
import { FormViews } from './components/OnboardingForm/constants';
import { FeedbackPage } from './components/FeedbackView/FeedbackPage';

const Main = () => {
  const { user, userData } = useAuth();

  return (
    <>
      <div
        className="background-container"
        style={{ overflowX: 'hidden' }}
      ></div>

      <Form />

      {user && (
        <Card>
          <pre>{JSON.stringify(user, null, 2)}</pre>
        </Card>
      )}

      {userData && (
        <Card>
          <pre>{JSON.stringify(userData, null, 2)}</pre>
        </Card>
      )}
    </>
  );
};

export const Root = () => {
  const modalToRender = useModal();

  return (
    <>
      {modalToRender}

      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute>
                <Main />
              </PublicRoute>
            }
          >
            <Route path="/login" element={<LoginRoute />} />
          </Route>

          <Route path="/content-preview" element={<ContentPreview />} />

          <Route path="/eligibility" element={<Eligibility />} />

          <Route path="/feedback" element={<FeedbackPage />} />

          <Route
            path="/onboarding"
            element={<Form initialView={FormViews.startOfFlow} />}
          />

          <Route path="/phone" element={<PhoneNumber />} />

          <Route path="/plans" element={<PlansPage />} />

          <Route
            path="/schedule-appointment"
            element={<ScheduleAppointment />}
          />

          <Route path="/thank-you" element={<ThankYou />} />

          <Route
            path="/unsubscribed-landing"
            element={
              <ProtectedRoute>
                <UnsubscribedLanding />
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<p>There's nothing here: 404!</p>} />
        </Routes>
      </Router>
    </>
  );
};
