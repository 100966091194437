import { useModalsContext } from './useModalsContext';

export const useModal = () => {
  const { state } = useModalsContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { modalProps, openedModal } = state;

  switch (openedModal) {
    // Add here the modals you want to show
    // e.g.
    // case ModalType.DashboardInfoModal:
    //   return <Modal {...modalProps} />;

    default:
      return null;
  }
};
