import { Flex, View } from 'native-base';
import { ReviewCard } from './ReviewCard';
import { REVIEWS } from './constants';

export const ReviewsCarousel = () => {
  return (
    <View>
      {/* @ts-ignore */}
      <Flex flexDir={'row'} overflow={'scroll'} gap={5} pb={3} px={2}>
        {REVIEWS.map(({ content, name, stars }, i) => (
          <ReviewCard key={i} content={content} name={name} numStars={stars} />
        ))}
      </Flex>
    </View>
  );
};
