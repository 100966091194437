import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../hooks';
import { AvailabilityResponse, CareModel } from '../types';

export const useProviderAvailability = ({
  care_model,
  timezone,
  user_id,
}:{
  care_model?: CareModel,
  timezone?: string,
  user_id?: string,
}) => {
  const { api } = useApi();

  const fetchAvailability = async (): Promise<AvailabilityResponse> => {
    return api({
      route: `/care_team_member/availability?timezone=${timezone}&user_id=${user_id}`,
    });
  };

  const { data, isInitialLoading, isLoading, isError } = useQuery<
    AvailabilityResponse,
    Error
  >({
    queryKey: ['public-provider-availability'],
    queryFn: fetchAvailability,
    enabled: care_model === CareModel.Collaborative && !!timezone && !!user_id,
    refetchOnWindowFocus: false,
  });

  return {
    isLoading: isInitialLoading || isLoading,
    isError,
    data,
  };
};
