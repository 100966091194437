import { useBreakpointValue } from 'native-base';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReadUserIdFromQueryParams } from '../../hooks/useReadUserIdFromQueryParams';
import { useScheduleAppointment } from '../../api/scheduleAppointment/useScheduleAppointment';
import { useFetchAvailability } from './useFetchAvailability';
import { useSegment } from '../../hooks/useSegment';
import { TRACKING_SCHEDULE_APPOINTMENT } from '../OnboardingForm/constants';
import {
  convertLocalBrowserDateToUserDate,
  getDateAvailability,
} from './utils';
import { ProviderTime } from '../../api/types';

type NavigateStateType = { bookedAppointment: boolean };

export const useScheduleAppointmentPage = () => {
  const { page: pageView } = useSegment();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { mutation } = useScheduleAppointment();
  const { userId: userIdQueryParam } = useReadUserIdFromQueryParams();
  const {
    availabilityResponse,
    availabilityType,
    isLoading: isFetchingAvailability,
    timezone,
  } = useFetchAvailability(userIdQueryParam);

  const [firstAvailableDay, setFirstAvailableDay] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<string | ProviderTime | null>(null);
  const [subHeaderHeight, setSubHeaderHeight] = useState<number>(0);
  const [createAppointmentError, setCreateAppointmentError] = useState('');
  const subHeaderRef = useRef<HTMLDivElement>();

  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });

  useEffect(() => {
    if (isFetchingAvailability) return;
    if (!availabilityResponse) return;

    // find the first day having at least one time slot available
    const firstAvailability = availabilityResponse.availability.find(
      (availability, _, allAvailabilities) => {
        // when creating a date starting from just a string, the browser by default reads
        // that as a UTC date and converts it into local timezone, but at this point our
        // availability date is already in the user timezone so we need to shift it accordingly
        const localBrowserDate = new Date(availability.date);
        const userTimezoneDate =
          convertLocalBrowserDateToUserDate(localBrowserDate);

        return !!getDateAvailability(allAvailabilities, userTimezoneDate)
          .length;
      }
    );

    if (!firstAvailability) return;

    // date in local browser timezone
    const firstAvailabilityDate = new Date(typeof firstAvailability.times[0] === 'string' ? firstAvailability.times[0] : firstAvailability.times[0]?.time);
    // conversion to the user timezone
    const firstAvailabilityConvertedToTimezone =
      firstAvailabilityDate.toLocaleString('en-US', {
        timeZone: timezone,
      });
    // recreate the date obj after the conversion
    const firstAvailableDay = new Date(firstAvailabilityConvertedToTimezone);

    setSelectedDate(firstAvailableDay);
    setFirstAvailableDay(firstAvailableDay);
  }, [availabilityResponse, isFetchingAvailability, timezone]);

  useEffect(() => {
    if (!userIdQueryParam) return;

    pageView(TRACKING_SCHEDULE_APPOINTMENT);
  }, [pageView, userIdQueryParam]);

  useLayoutEffect(() => {
    // the subheader has a dynamic width based on the different
    // screen sizes, so we read it once rendered in order to
    // compute the max content height
    setSubHeaderHeight(
      (subHeaderRef?.current && subHeaderRef.current.clientHeight) || 0
    );
  }, []);

  const onSkip = useCallback(() => {
    navigate(`/thank-you${search}`);
  }, [navigate, search]);

  const onSelectDate = useCallback(setSelectedDate, [setSelectedDate]);
  const onSelectTime = useCallback(setSelectedTime, [setSelectedTime]);

  const onCreateMeeting = useCallback(async () => {
    if (!selectedTime || !userIdQueryParam || !availabilityResponse) return;

    const selectedIsoString = typeof selectedTime === 'string' ? selectedTime : selectedTime.time

    try {
      await mutation.mutateAsync({
        meeting_type_id: availabilityResponse.meeting_type.id,
        meeting_date: selectedIsoString,
        user_id: userIdQueryParam,
        provider_id: typeof selectedTime === 'string' ? undefined : selectedTime.provider_id.toString(),
      });

      const navigateState: NavigateStateType = { bookedAppointment: true };
      navigate(`/thank-you${search}`, { state: navigateState });
    } catch (error: any) {
      setCreateAppointmentError(
        typeof error?.message === 'string'
          ? error.message
          : 'An error occurred while creating the appointment. Please try again later.'
      );
    }
  }, [
    availabilityResponse,
    mutation,
    navigate,
    search,
    selectedTime,
    userIdQueryParam,
  ]);

  const dismissCreateAppointmentError = useCallback(
    () => setCreateAppointmentError(''),
    [setCreateAppointmentError]
  );

  return {
    availabilityResponse,
    availabilityType,
    createAppointmentError,
    dismissCreateAppointmentError,
    firstAvailableDay,
    isCreatingNewAppointment: mutation.isLoading,
    isFetchingAvailability,
    isMobile,
    onCreateMeeting,
    onSelectDate,
    onSelectTime,
    onSkip,
    selectedDate,
    selectedTime,
    subHeaderHeight,
    subHeaderRef,
    userIdQueryParam,
    timezone,
  };
};
