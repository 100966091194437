export enum DiagnosisCategory {
  IBD = 'IBD',
  IBS = 'IBS',
  Other = 'Other',
  UpperGI = 'Upper GI',
}

export type Diagnosis = {
  id: string;
  diagnosis: string;
  category?: DiagnosisCategory;
};

export enum MeetingTypeId {
  InitialConsultation = 'Initial Consultation',
  InitialCoachCall = 'Initial Coach Call',
}

export type MeetingType = {
  id: string;
  length: number;
  name: MeetingTypeId;
};

export type ProviderTime = {
  provider_id: number
  time: string // ISO string
}

export type AvailabilityEntry = {
  date: string;
  times: (string | ProviderTime)[]
};

export type AvailabilityResponse = {
  meeting_type: MeetingType;
  availability: AvailabilityEntry[]
};

export enum CareModel {
  DigitalOnly = "Digital-only",
  CoachOnly = "Coach-only",
  Collaborative = "Collaborative"
}
