import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../hooks';
import { AvailabilityResponse, CareModel, MeetingTypeId } from '../types';

export const useCoachAvailability = ({
  coach_uuid,
  timezone,
  care_model,
}: {
  coach_uuid?: string,
  timezone?: string,
  care_model?: CareModel
}) => {
  const { api } = useApi();

  const fetchAvailability = async (): Promise<AvailabilityResponse> => {
    return api({
      route: `/coach/${coach_uuid}/availability?appt_type=${MeetingTypeId.InitialCoachCall}&timezone=${timezone}`,
    });
  };

  const { data, isInitialLoading, isLoading, isError } = useQuery<
    AvailabilityResponse,
    Error
  >({
    queryKey: ['public-coach-availability'],
    queryFn: fetchAvailability,
    enabled: !!coach_uuid && !!timezone && care_model === CareModel.CoachOnly,
    refetchOnWindowFocus: false,
  });

  return {
    isLoading: isInitialLoading || isLoading,
    isError,
    data,
  };
};
