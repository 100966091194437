import { useMutation } from '@tanstack/react-query';
import { useApi } from '../../hooks';

type MeetingPayload = {
  meeting_type_id: string;
  meeting_date: string;
  user_id: string;
  provider_id?: string
};

export const useScheduleAppointment = () => {
  const { api } = useApi({ throwIfError: true });

  const mutation = useMutation({
    mutationFn: (meeting: MeetingPayload) => {
      return api({
        route: `/user/meeting/`,
        method: 'POST',
        payload: meeting,
      });
    },
  });

  return { mutation };
};
