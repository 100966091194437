import { Affiliate, AffiliateType } from '../../models/affiliate';

const bubbleImg = './../mind-gut-i-darker.png';
const notesImg = './../notes.png';
const joyImg = './../joy.png';
const foodsImg = './../foods.png';
const shakingHandsImg = './../hands_shaking.png';
const mobilePhoneImg = './../mobile_phone.png';

const TOMATO = '#f15b33';
const MEDIUM_TURQUOISE = '#56cec6';
const GOLD = '#f7d31b';

const DEFAULT_BULLETS = [
  {
    bg: GOLD,
    bgSize: undefined,
    imgUrl: mobilePhoneImg,
    text: 'Expert digestive care team you can talk to anytime, from anywhere',
    title: 'Convenient Virtual Care',
  },
  {
    bg: TOMATO,
    bgSize: '75%',
    imgUrl: notesImg,
    text: 'Find your trigger foods and build a diet that works for you',
    title: 'Eat with Confidence',
  },
  {
    bg: MEDIUM_TURQUOISE,
    bgSize: undefined,
    imgUrl: bubbleImg,
    text: 'Powerful guided meditations and practices to break the stress- symptom cycle',
    title: 'Calm Your Mind to Calm Your Gut',
  },
  {
    bg: TOMATO,
    bgSize: '50%',
    imgUrl: joyImg,
    text: 'Comprehensive gut health tracker to see how your symptoms change over time',
    title: 'Manage Symptoms Effectively',
  },
  {
    bg: MEDIUM_TURQUOISE,
    bgSize: '75%',
    imgUrl: foodsImg,
    text: 'Grocery-finding tool and barcode scanner to remove the guesswork at the store',
    title: 'Find Foods That Work for You',
  },
  {
    bg: GOLD,
    bgSize: '60%',
    imgUrl: shakingHandsImg,
    text: 'Educational content written by GI experts and clinicians',
    title: 'Learn From Gut Health Experts',
  },
];

export const generateBulletPoints = (
  fetchedAffiliate: Affiliate | undefined
) => {
  if (fetchedAffiliate?.affiliate_type !== AffiliateType.healthSystem) {
    return DEFAULT_BULLETS;
  }

  const [expertBullet, ...rest] = DEFAULT_BULLETS;
  return [
    {
      ...expertBullet,
      text: `Complementary digestive care support, available anytime, anywhere - working alongside your ${fetchedAffiliate?.name} provider.`,
    },
    ...rest,
  ];
};
