import { Flex, View } from 'native-base';
import { ReviewCard } from './ReviewCard';
import { REVIEWS } from './constants';

export const MobileReviewsCarousel = () => {
  return (
    <View>
      <Flex
        flexDir={'column'}
        // @ts-ignore
        gap={'24px'}
        alignItems={'center'}
        overflow={'scroll'}
        pb={3}
        px={2}
      >
        {REVIEWS.map(({ content, name, stars }) => (
          <ReviewCard content={content} name={name} numStars={stars} />
        ))}
      </Flex>
    </View>
  );
};
