import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import {
  Pressable,
  View,
  Image,
  Heading,
  Flex,
  VStack,
  Center,
  Text,
  Box,
  useBreakpointValue,
} from 'native-base';

import { Link } from '../ui/Link';
import HeaderNav from '../HeaderNav/HeaderNav';
import { useGoogleTagManager } from '../../hooks/useGoogleTagManager';
import { useSegment } from '../../hooks/useSegment';
import { LOCAL_STORAGE_USER_INFO_KEY } from '../../constants/misc';
import {
  TRACKING_DOWNLOAD_APP_PAGE,
  TRACKING_PURCHASE,
  TRACKING_PURCHASE_LOCAL_STORAGE_KEY,
} from '../OnboardingForm/constants';
import { useThankYouPage } from './useThankYouPage';
import styled from '@emotion/styled';

const qrCode = './../onelinkto_nqnxxq-cp.png';
const appImg = './../2b_screenshots.png';
const footerImg = './../Ayble-LLLo-21.png';
const iosBtn = './../Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.png';
const playStoreBtn = './../google-play-badge.png';
const iosDownloadLink =
  'https://apps.apple.com/us/app/ayble-health-gi-gut-care/id1642365544';
const androidDownloadLink =
  'https://play.google.com/store/apps/details?id=com.ayblehealth.app';

const ImgContainer = styled('div')`
  & > div > div {
    background-position: top;
  }
`;

const GRAY_HEX = '#D9D9D9';

function ThankYou() {
  const { trackEventInGoogleTagManager } = useGoogleTagManager();
  const { page: pageView } = useSegment();
  const { bookedAppointment, redirectToHome } = useThankYouPage();

  const isTablet = useBreakpointValue({
    base: true,
    lg: false,
  });

  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });

  const trackPurchaseComplete = !!localStorage.getItem(
    TRACKING_PURCHASE_LOCAL_STORAGE_KEY
  );

  useEffect(() => {
    pageView(TRACKING_DOWNLOAD_APP_PAGE);

    if (trackPurchaseComplete) {
      trackEventInGoogleTagManager(TRACKING_PURCHASE);
      localStorage.removeItem(TRACKING_PURCHASE_LOCAL_STORAGE_KEY);
      localStorage.removeItem(LOCAL_STORAGE_USER_INFO_KEY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (redirectToHome) return <Navigate to={'/'} replace />;

  const title = bookedAppointment
    ? `You're booked! Download the Ayble Health app to prep for your first visit!`
    : `You're all set! Download the Ayble Health app to schedule your first care team visit.`;

  return (
    <View>
      <HeaderNav hideBack />
      <VStack
        px={isTablet ? '16px' : '80px'}
        pt={4}
        pb={isMobile ? 20 : 40}
        w={'100%'}
        maxW={'1200px'}
        mt={5}
        mx={'auto'}
      >
        <Flex flex={1} flexDirection={isTablet ? 'column' : 'row'} px={4}>
          <VStack width={isTablet ? '100%' : '70%'}>
            <Box maxW={'680px'} mx={isTablet ? 'auto' : 0}>
              <Heading
                fontSize={'3xl'}
                mr={isTablet ? 0 : 20}
                mb={isMobile ? 4 : 8}
                mt={isMobile ? 2 : 4}
                textAlign={'left'}
                lineHeight={'35px'}
              >
                {title}
              </Heading>

              <Text fontSize={'lg'} mr={isTablet ? 0 : 20}>
                Manage appointments, learn what impacts your symptoms, track
                care plan progress, and more — all in the app.
              </Text>

              <Flex
                borderColor={GRAY_HEX}
                backgroundColor={'gray.50'}
                borderRadius={12}
                borderWidth={1}
                px={8}
                py={6}
                mr={isTablet ? 0 : 20}
                mt={6}
              >
                <Flex
                  gap={6}
                  mb={isMobile ? 0 : 2}
                  flexDirection={isMobile ? 'column' : 'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Box
                    alignItems={'center'}
                    width={190}
                    height={190}
                    borderRadius={1}
                    borderWidth={2}
                    padding={1}
                    borderStyle="dashed"
                    borderColor={'blue.300'}
                  >
                    <Image
                      alt="Ayble App"
                      resizeMode={'contain'}
                      w={'100%'}
                      h={'100%'}
                      source={{
                        uri: qrCode,
                      }}
                    />
                  </Box>

                  <VStack
                    flex={'auto'}
                    maxH={'100%'}
                    h={isMobile ? 160 : isTablet ? 190 : '100%'}
                    w={'100%'}
                    maxW={212}
                    alignItems={'center'}
                    justifyContent={'center'}
                    mt={isMobile ? 2 : undefined}
                  >
                    <Pressable
                      my={isMobile ? 1 : 2}
                      h={'100%'}
                      maxH={20}
                      flex={1}
                      w={'100%'}
                      href={iosDownloadLink}
                      isExternal
                    >
                      <Image
                        alt="Ayble App"
                        style={{ height: '100%', width: '100%' }}
                        source={{ uri: iosBtn }}
                        resizeMode="contain"
                        resizeMethod="scale"
                      />
                    </Pressable>

                    <Pressable
                      my={isMobile ? 1 : 2}
                      h={'100%'}
                      maxH={20}
                      flex={1}
                      w={'100%'}
                      overflow={'hidden'}
                      href={androidDownloadLink}
                      isExternal
                    >
                      <Image
                        alt="Ayble App"
                        style={{ height: '100%', width: '100%' }}
                        source={{ uri: playStoreBtn }}
                        resizeMode="contain"
                        resizeMethod="scale"
                      />
                    </Pressable>
                  </VStack>
                </Flex>
                <Box my={4} width={'100%'} maxW={'426px'} mx={'auto'}>
                  <Text>
                    Click on{' '}
                    <Link
                      isExternal
                      href={iosDownloadLink}
                      isUnderlined={false}
                    >
                      App Store
                    </Link>{' '}
                    or{' '}
                    <Link
                      isExternal
                      href={androidDownloadLink}
                      isUnderlined={false}
                    >
                      Google Play
                    </Link>{' '}
                    store icon to download the app, or scan the QR code with
                    your smart phone camera.
                  </Text>
                </Box>
              </Flex>
            </Box>
          </VStack>

          {!isTablet && (
            <Box
              pt={isTablet ? 6 : null}
              w={'100%'}
              maxW={'308px'}
              h={'444px'}
              alignSelf={'flex-end'}
            >
              <Image
                alt="App Screenshot"
                resizeMethod={'scale'}
                resizeMode={'contain'}
                w={'100%'}
                h={'100%'}
                source={{
                  uri: appImg,
                }}
              />
            </Box>
          )}
        </Flex>

        <Center mt={20} px={4}>
          <ImgContainer>
            <Image
              size={60}
              resizeMethod={'scale'}
              resizeMode={'cover'}
              borderRadius={'full'}
              source={{
                uri: footerImg,
              }}
              alt="Footer Image"
            />
          </ImgContainer>

          <Text fontWeight={'normal'} mt={4} textAlign={'center'}>
            Need help or want to manage your subscription? <br />
            Contact{' '}
            <Link href="mailto:support@ayblehealth.com">
              support@ayblehealth.com
            </Link>
          </Text>
        </Center>
      </VStack>
    </View>
  );
}

export default ThankYou;
