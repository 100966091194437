import { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  Heading,
  Text,
  Input,
  FormControl,
  Button,
  Link,
  Flex,
} from 'native-base';
import { useSegment } from '../../hooks/useSegment';
import { useNavigate, useLocation } from 'react-router-dom';

import useRegisterPost from '../../api/register/mutations/useRegisterPost';
import HeaderNav from '../HeaderNav/HeaderNav';
import useEligibilityRouting from '../../hooks/useEligibilityRouting';

const TRACK_PHONE_COLLECTION = 'phone_collection';

export const PhoneNumber = () => {
  const navigate = useNavigate();
  const { track } = useSegment();
  const { search, state } = useLocation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [agreeChecked, setAgreeCheck] = useState(false);
  const { mutation } = useRegisterPost();

  useEffect(() => {
    const storedPhone = localStorage.getItem('USER_PHONE');
    if (storedPhone) setPhoneNumber(storedPhone);
  }, []);

  useEligibilityRouting({
    checkEligibility: mutation.data?.check_eligible,
    mutationSuccess: mutation.isSuccess,
    state,
    search,
  });

  useEffect(() => {
    track(TRACK_PHONE_COLLECTION, null);
  }, [track]);

  const formatPhoneNumber = (text) => {
    const cleaned = text.replace(/\D/g, '');

    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      const formattedNumber = `${match[1]}${match[2] ? '-' : ''}${match[2]}${
        match[3] ? '-' : ''
      }${match[3]}`;
      return formattedNumber;
    }

    return text;
  };

  return (
    <Box h={'100vh'}>
      <HeaderNav
        handleBack={() =>
          navigate(`/${search}`, {
            state: { toRegister: true },
          })
        }
      />
      <VStack mt={10} alignItems={'center'}>
        <Heading>What's your phone number?</Heading>
        <Text fontSize={'xl'} textAlign={'center'} maxW={'700px'} mt={5} px={8}>
          We’ll text you with appointment reminders and other important updates
          about your program. You can opt out at any time.
        </Text>
        <Box w={'100%'} maxW={'650px'} px={8}>
          <FormControl.Label mt={12}>
            Mobile number (XXX-XXX-XXXX)
          </FormControl.Label>
          <Input
            maxW={'650px'}
            type="text"
            maxLength={12}
            value={phoneNumber}
            onChangeText={(value) => {
              const formattedText = formatPhoneNumber(value);
              setPhoneNumber(formattedText);
            }}
          />
          <Flex flexDir={'row'} alignItems={'flex-start'} mt={10}>
            <input
              type="checkbox"
              style={{
                zoom: 1.5,
                marginLeft: 0,
              }}
              checked={agreeChecked}
              onChange={() => setAgreeCheck((v) => !v)}
            />
            <Text px={2}>
              By checking this box, I agree to receive text messages from Ayble
              Health at the number I provided and that these texts may be sent
              using an autodialer. I understand that my agreement is not a
              condition of any purchase or of using the service. I understand
              and agree that these text messages may be sent unencrypted, that
              the transmission of such text messages are inherently insecure,
              and that there is a risk that such text messages could be
              intercepted and read by a third party. I understand that I can
              reply "STOP" to opt-out at any time and accept the terms outlined
              in the{' '}
              <Link
                _text={{ textTransform: 'lowercase' }}
                href="https://www.ayblehealth.com/privacy-policy"
                isExternal
              >
                privacy policy.
              </Link>
            </Text>
          </Flex>
        </Box>

        <Button
          mt={12}
          isDisabled={
            phoneNumber.length !== 12 || mutation.isLoading || !agreeChecked
          }
          w="200px"
          alignSelf={'center'}
          onPress={async () => {
            localStorage.setItem('USER_PHONE', phoneNumber);
            try {
              await mutation.mutateAsync({
                email: state.email,
                phoneNumber,
                referrer: state.referrer,
                affiliate: state.affiliate,
              });
            } catch (error) {
              console.error('failed to save phone number:', error);
            }
          }}
        >
          Next
        </Button>
        <Link
          mt={5}
          onPress={async () => {
            try {
              await mutation.mutateAsync({
                email: state.email,
                referrer: state.referrer,
                affiliate: state.affiliate,
              });
            } catch (error) {
              console.error('failed to check eligibility', error);
            }
          }}
        >
          Skip this step
        </Link>
      </VStack>
    </Box>
  );
};

export default PhoneNumber;
