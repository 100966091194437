import { Box, Button, Heading, Text, TextArea, VStack } from 'native-base';
import HeaderNav from '../HeaderNav/HeaderNav';
import { NAV_HEADER_HEIGHT_PX } from '../../constants/misc';
import { GradientBox } from '../ui/style';
import { ReviewsCarousel } from '../ui/ReviewsCarousel';
import { Dimensions } from 'react-native';
import { MeetingTypeId } from '../../api/types';

const ACTION_BUTTONS_HEIGHT_PX = 96;

type Props = {
  appointment_type: MeetingTypeId;
  feedbackTxt: string;
  isLoading: boolean;
  onFeedbackTxtChange: (feedbackTxt: string) => void;
  onSkip: () => void;
  sendFeedback: () => Promise<void>;
};

export const DesktopFeedbackPage = ({
  appointment_type,
  feedbackTxt,
  isLoading,
  onFeedbackTxtChange,
  onSkip,
  sendFeedback,
}: Props) => {
  const screenHeight = Dimensions.get('window').height;
  const visitTypeTxt =
    appointment_type === MeetingTypeId.InitialConsultation
      ? 'provider'
      : 'coach';

  return (
    <VStack>
      <HeaderNav hideBack />

      <GradientBox>
        <VStack
          alignItems={'center'}
          height={screenHeight - NAV_HEADER_HEIGHT_PX}
          maxHeight={'550px'}
          mb={12}
        >
          <Box alignItems={'center'}>
            <Heading color={'secondary.500'} fontWeight={'normal'} pt={6}>
              You’re in! Let’s book your <br /> first {visitTypeTxt} visit.
            </Heading>
          </Box>

          <VStack
            justifyContent={'center'}
            space={1}
            flex={1}
            mb={`${ACTION_BUTTONS_HEIGHT_PX}px`}
          >
            <TextArea
              isDisabled={isLoading}
              autoCompleteType={'off'}
              type="text"
              placeholder="Optional: share any context or topics you’d like to cover in your first visit."
              backgroundColor={'muted.50'}
              h={64}
              w={'420px'}
              maxW={'420px'}
              p={5}
              rounded={'lg'}
              fontSize={'lg'}
              value={feedbackTxt}
              onChange={(e: any) => onFeedbackTxtChange(e.target.value)}
            />
          </VStack>

          {/* Action Buttons */}
          <Box
            w={'100%'}
            position={'absolute'}
            bottom={0}
            px={6}
            height={`${ACTION_BUTTONS_HEIGHT_PX}px`}
          >
            <Button
              alignSelf={'center'}
              bgColor={'secondary.500'}
              isDisabled={!feedbackTxt || isLoading}
              isLoading={isLoading}
              onPress={sendFeedback}
              w={'400px'}
            >
              <Text color={'white'} fontWeight={'bold'}>
                Share With My Care Team
              </Text>
            </Button>

            <Button
              alignSelf={'center'}
              bgColor={'transparent'}
              isDisabled={isLoading}
              onPress={onSkip}
              variant={'ghost'}
              w={'400px'}
            >
              <Text color={'secondary.500'} fontWeight={'bold'}>
                Skip for now
              </Text>
            </Button>
          </Box>
        </VStack>

        <VStack alignItems={'center'} pb={12}>
          <ReviewsCarousel />
        </VStack>
      </GradientBox>
    </VStack>
  );
};
