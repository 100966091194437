import React from 'react';
import { Box, HStack, ITheme, Text } from 'native-base';
import { useAffiliateBanner } from '../Affiliate/useAffiliateBanner';
import { AffiliateType } from '../../models/affiliate';
import styled from '@emotion/styled';
import { BODY_2_MOBILE_SIZE_PX, BODY_2_SIZE_PX } from './constants';

const GradientBox = styled('div')<{ theme?: ITheme }>`
  background-image: linear-gradient(to right, #72ccc5, #ffb624);
`;

const bannerMessageAffiliateTypeMap: Record<AffiliateType, string> = {
  [AffiliateType.employer]: 'Digestive programs - at no cost to you!',
  [AffiliateType.healthPlan]: 'Gut health programs covered by insurance',
  [AffiliateType.healthSystem]: 'Get complimentary digestive care: nutrition & mind-gut guidance',
};

export const Banner = () => {
  const { affiliate: fetchedAffiliate } = useAffiliateBanner({});

  const defaultBannerText = fetchedAffiliate?.affiliate_type
    ? bannerMessageAffiliateTypeMap[fetchedAffiliate.affiliate_type]
    : bannerMessageAffiliateTypeMap[AffiliateType.healthPlan];

  const bannerText = fetchedAffiliate?.banner_text || defaultBannerText;

  return (
    <GradientBox>
      <HStack p={4} justifyContent={'center'}>
        <Text
          fontSize={{ base: BODY_2_MOBILE_SIZE_PX, sm: BODY_2_SIZE_PX }}
          fontWeight={'medium'}
          color={'white'}
        >
          <Box
            color={'secondary.500'}
            flex={1}
            display={'flex'}
            alignItems={'center'}
            testID={'affiliate-banner'}
          >
            <span style={{ textAlign: 'center' }}>{bannerText}</span>
          </Box>
        </Text>
      </HStack>
    </GradientBox>
  );
};
