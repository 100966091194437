import { useAuth } from './useAuth';
import { useReadUserIdFromQueryParams } from './useReadUserIdFromQueryParams';

export const useReadAuthUserIdWithQueryParamsLookup = (): {
  userId: string | undefined;
} => {
  const { isAuthenticated, user } = useAuth();
  const { userId: decodedUserIdQueryParam } = useReadUserIdFromQueryParams();

  // When users are authenticated, they already completed their onboarding flow/payment earlier
  // so, we get their id from the authentication hook.
  // When users are not, we read their id from the url as they either come from the
  // schedule a meeting page (after going through Stripe payment) or from the eligibility
  // page after the successful check.
  // If no user id is present, they are not authenticated so they will be redirected to home.
  const userId = isAuthenticated ? user?.id : decodedUserIdQueryParam;

  // if undefined, the user is not authenticated,
  // neither we have an id in the query params
  return { userId };
};
