import { Location, useLocation } from 'react-router-dom';
import { useReadAuthUserIdWithQueryParamsLookup } from '../../hooks/useReadAuthUserIdWithQueryParamsLookup';

export const useThankYouPage = () => {
  const { userId } = useReadAuthUserIdWithQueryParamsLookup();
  const { state }: Location<{ bookedAppointment: boolean }> = useLocation();

  if (!userId) return { redirectToHome: true };

  return {
    bookedAppointment: !!state?.bookedAppointment,
    redirectToHome: false,
  };
};
