import styled from '@emotion/styled';
import {
  Badge,
  Button,
  HStack,
  Image,
  Pressable,
  Text,
  useBreakpointValue,
  VStack,
} from 'native-base';
import React, { useState, useMemo, useEffect } from 'react';
import {
  exceedTimeBound,
  formatTime,
  formatWeekRange,
  getDateAvailability,
  getTimeZoneShortName,
  getWeekStart,
} from './utils';
import { AvailabilityEntry, ProviderTime } from '../../api/types';

const chevronRight = './../chevron-right.png';
const chevronLeft = './../chevron-left.png';

const DISABLED_COLOR = '#BCC7D7';
const DISABLED_BG_COLOR = '#ECEFF1';
const WEEK_DAYS = 7;
// The number of weeks users can advance to
// starting from today
const MAX_WEEKS_COUNT = 3;

const ThreeColumnsGrid = styled('div')<{ cols: number }>`
  display: grid;
  grid-template-columns: repeat(${(p) => p.cols}, 1fr); // Always 3 columns
  gap: 10px; // Space between items
`;

interface DateTimePickerProps {
  availability: AvailabilityEntry[];
  firstAvailableDay: Date;
  onSelectDate: (selectedDate: Date) => void;
  onSelectTime: (selectedTime: string | ProviderTime | null) => void;
  selectedDate: Date;
  selectedTime: string | ProviderTime | null;
  timezone: string | undefined;
}

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  availability,
  firstAvailableDay,
  onSelectDate,
  onSelectTime,
  selectedDate,
  selectedTime,
  timezone,
}) => {
  const [weekStart, setWeekStart] = useState<Date>(firstAvailableDay);

  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });

  const handleDateChange = (date: Date) => {
    onSelectDate(date);
    onSelectTime(null);
  };

  const handleWeekNavigation = (direction: 'prev' | 'next') => {
    const newStart = new Date(weekStart);
    newStart.setDate(
      weekStart.getDate() + (direction === 'next' ? WEEK_DAYS : -WEEK_DAYS)
    );

    setWeekStart(newStart);
  };

  // the time slots available of the selected date
  const availableTimes = useMemo(() => {
    const selectedDateAvailability = getDateAvailability(
      availability,
      selectedDate
    );

    return selectedDateAvailability.map((time) => ({
      time,
      label: formatTime(typeof time === 'string' ? time : time.time, timezone),
    }));
  }, [availability, selectedDate, timezone]);

  useEffect(() => {
    setWeekStart(getWeekStart(firstAvailableDay));
  }, [firstAvailableDay]);

  return (
    <VStack flex={1} w={'100%'}>
      <VStack overflow={'auto'}>
        {/* Week Navigation */}
        <HStack alignItems={'center'} justifyContent={'space-between'}>
          <Text color={'secondary.200'} fontWeight={'bold'}>
            {formatWeekRange(weekStart)}
          </Text>

          <HStack>
            <Pressable
              variant={'closer'}
              onPress={() => handleWeekNavigation('prev')}
              mr={2}
              disabled={weekStart < new Date()}
            >
              <Image
                alt={'back button'}
                src={chevronLeft}
                style={{ width: 24, height: 24 }}
              />
            </Pressable>

            <Pressable
              variant={'closer'}
              onPress={() => handleWeekNavigation('next')}
              disabled={exceedTimeBound(weekStart, MAX_WEEKS_COUNT)}
            >
              <Image
                alt={'forth button'}
                src={chevronRight}
                style={{ width: 24, height: 24 }}
              />
            </Pressable>
          </HStack>
        </HStack>

        {/* Date Selection */}
        <HStack maxW={'100%'} overflow={'auto'}>
          <HStack flex={1} justifyContent={'space-between'} pt={6}>
            {Array.from({ length: WEEK_DAYS }).map((_, i) => {
              const date = new Date(weekStart);
              date.setDate(weekStart.getDate() + i);
              // Prevent selecting more than one day in the past
              const yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);

              const hasTimeAvailable = !!getDateAvailability(availability, date)
                .length;
              const isDisabled = date < yesterday || !hasTimeAvailable;
              const isActive =
                selectedDate.toDateString() === date.toDateString();

              return (
                <Button
                  bgColor={
                    isDisabled
                      ? DISABLED_BG_COLOR
                      : isActive
                      ? 'secondary.200'
                      : 'transparent'
                  }
                  borderWidth={1}
                  borderColor={isDisabled ? DISABLED_COLOR : 'secondary.200'}
                  borderRadius={'lg'}
                  height={{ base: '72px', sm: '72px' }}
                  width={{ base: '48px', sm: '72px' }}
                  paddingX={{ base: '24px', sm: '30px' }}
                  marginRight={{ base: '12px', sm: '8px', md: undefined }}
                  key={i}
                  onPress={() => !isDisabled && handleDateChange(date)}
                  disabled={isDisabled}
                  pointerEvents={isDisabled ? 'none' : 'auto'}
                >
                  <Text
                    color={
                      isDisabled
                        ? DISABLED_COLOR
                        : isActive
                        ? 'white'
                        : 'secondary.200'
                    }
                    fontWeight={'normal'}
                  >
                    {date.toLocaleDateString('en-US', {
                      weekday: 'short',
                    })}
                  </Text>

                  <Text
                    textAlign={'center'}
                    color={
                      isDisabled
                        ? DISABLED_COLOR
                        : isActive
                        ? 'white'
                        : 'secondary.200'
                    }
                    fontWeight={'bold'}
                  >
                    {date.toLocaleDateString('en-US', {
                      day: 'numeric',
                    })}
                  </Text>
                </Button>
              );
            })}
          </HStack>
        </HStack>

        {/* Time Slots */}
        <HStack
          alignItems={'center'}
          justifyContent={'space-between'}
          pt={10}
          pb={5}
        >
          <Text color={'secondary.200'} fontWeight={'bold'}>
            Times Available
          </Text>

          <Badge
            variant={'outline'}
            alignSelf="center"
            color={'secondary.200'}
            rounded={'xl'}
            borderColor={DISABLED_COLOR}
          >
            <Text color={'secondary.200'} fontWeight={'normal'}>
              {getTimeZoneShortName(timezone)}
            </Text>
          </Badge>
        </HStack>

        <ThreeColumnsGrid cols={isMobile ? 2 : 3}>
          {availableTimes.map(({ time, label }) => {
            const isoString = typeof time === 'string' ? time : time.time;
            const isSelected = selectedTime === time;

            return (
              <Button
                bgColor={isSelected ? 'secondary.200' : 'transparent'}
                borderWidth={1}
                borderColor={'secondary.200'}
                key={isoString}
                onPress={() => onSelectTime(time)}
              >
                <Text
                  color={isSelected ? 'white' : 'secondary.200'}
                  fontWeight={'bold'}
                >
                  {label}
                </Text>
              </Button>
            );
          })}
        </ThreeColumnsGrid>
      </VStack>
    </VStack>
  );
};
