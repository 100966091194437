import { useApi } from '../../hooks';
import { useQuery } from '@tanstack/react-query';
import { CareModel } from '../types';

export type UserPublicInfo = {
  coach_id: string;
  provider_id: string;
  timezone: string;
  care_model: CareModel;
};

export const useUserPublicInfo = (
  userId: string | undefined,
  throwIfError = false
) => {
  const { api } = useApi({ throwIfError });

  const fetchPublicInfo = async (): Promise<UserPublicInfo> => {
    return api({
      route: `/user/${userId}/`,
    });
  };

  const { data, isInitialLoading, isLoading, isError } = useQuery<
    UserPublicInfo,
    Error
  >({
    queryKey: ['public-user-info', userId],
    queryFn: fetchPublicInfo,
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });

  return {
    isLoading: isLoading || isInitialLoading,
    isError,
    data,
  };
};
