// the following events are not bounded to any specific survey page,
// so they are not in the map above but the live independently
export const TRACKING_START_SIGN_UP = 'signup_start';
export const TRACKING_SIGN_UP_SUCCEDEED = 'signup_succeeded';
export const TRACKING_SIGN_UP_FAILED = 'signup_failed';
export const TRACKING_PREGNANCY_REJECT = 'user_ineligible_3';
export const TRACKING_DISORDER_REJECT = 'user_ineligible_4';
export const TRACKING_GI_SURGERY_REJECT = 'user_ineligible_5';
export const TRACKING_MEMBERSHIP_PAGE = 'choose_membership';
export const TRACKING_STRIPE_PAYMENT_PAGE = 'stripe_payment_page';
export const TRACKING_DOWNLOAD_APP_PAGE = 'download_app_page';
export const TRACKING_FREE_CONSULTATION_LINK_CLICK = 'click_for_consultation';
export const TRACKING_FREE_CONSULTATION_HOME_LINK_CLICK =
  'home_click_for_consultation';
export const TRACKING_ELIGIBILITY_CHECK = 'check_member_eligibility';
export const TRACKING_CARD_SCAN_START = 'card_scan_start';
export const TRACKING_CARD_SCAN_SUCCESS = 'card_scan_success';
export const TRACKING_CARD_SCAN_FAILED = 'card_scan_fail';
export const TRACKING_SCHEDULE_APPOINTMENT = 'appointment_scheduler_screen';
export const TRACKING_SOLICIT_FEEDBACK = 'solicit_feedback_screen';
export const TRACKING_PURCHASE_LOCAL_STORAGE_KEY = 'finishedOnboarding';

// Events used for tracking in GTM
export const TRACKING_ONBOARDING_STARTED = 'Onboarding Started';
export const TRACKING_ONBOARDING_COMPLETED = 'Create account';
export const TRACKING_PLANS_SCREEN = 'Select plan';
export const TRACKING_INITIATE_CHECKOUT = 'Go To Checkout';
export const TRACKING_ELIGIBILITY_CHECK_SCREEN = 'Eligibility Check Screen';
export const TRACKING_PURCHASE = 'Thank You';

export enum FormViews {
  landingPage = 'landingPage', // -2
  startOfFlow = 'symptomsList', // 1
  transitionGoal = 'transitionGoal', // 2
  questionGiCondition = 'questionGiCondition', // 3
  assessmentColitis = 'assessmentColitis', // 4
  assessmentCrohns = 'assessmentCrohns', // 4
  assessmentIbs = 'assessmentIbs', // 4
  assessmentNoDiagnosis = 'assessmentNoDiagnosis', // 4
  questionSexGender = 'questionSexGender', // 5
  questionIdentity = 'questionIdentity', // 6
  questionPregnancy = 'questionPregnancy', // 7
  questionEatingDisorder = 'questionEatingDisorder', // 8
  questionGiSurgery = 'questionGiSurgery', // 9
  endOfFlow = 'accountCreation', // 10
}

export const FORM_VIEW = {
  [FormViews.landingPage]: {
    label: FormViews.landingPage,
    percentComplete: 0,
    trackingLabel: '1_landing_page',
    styleOverride: { maxWidth: '100%' },
  },
  [FormViews.startOfFlow]: {
    label: FormViews.startOfFlow,
    percentComplete: 0,
    trackingLabel: 'symptoms_questions_list',
  },
  [FormViews.transitionGoal]: {
    label: FormViews.transitionGoal,
    percentComplete: 10,
    trackingLabel: '5_see_if_eligible_page',
  },
  [FormViews.questionGiCondition]: {
    label: FormViews.questionGiCondition,
    percentComplete: 20,
    trackingLabel: '12_gi_symptoms_question',
  },
  [FormViews.assessmentColitis]: {
    label: FormViews.assessmentColitis,
    percentComplete: 30,
    trackingLabel: '13_assessment_A',
  },
  [FormViews.assessmentCrohns]: {
    label: FormViews.assessmentCrohns,
    percentComplete: 30,
    trackingLabel: '13_assessment_B',
  },
  [FormViews.assessmentIbs]: {
    label: FormViews.assessmentIbs,
    percentComplete: 30,
    trackingLabel: '13_assessment_C',
  },
  [FormViews.assessmentNoDiagnosis]: {
    label: FormViews.assessmentNoDiagnosis,
    percentComplete: 30,
    trackingLabel: '13_assessment_D',
  },
  [FormViews.questionSexGender]: {
    label: FormViews.questionSexGender,
    percentComplete: 40,
    trackingLabel: '6_sex_and_gender_question',
  },
  [FormViews.questionIdentity]: {
    label: FormViews.questionIdentity,
    percentComplete: 50,
    trackingLabel: '7_ethnicity_question',
  },
  [FormViews.questionPregnancy]: {
    label: FormViews.questionPregnancy,
    percentComplete: 60,
    trackingLabel: '8_pregnancy_question',
  },
  [FormViews.questionEatingDisorder]: {
    label: FormViews.questionEatingDisorder,
    percentComplete: 70,
    trackingLabel: '9_eating_disorder_question',
  },
  [FormViews.questionGiSurgery]: {
    label: FormViews.questionGiSurgery,
    percentComplete: 80,
    trackingLabel: '10_gi_surgery_question',
  },
  [FormViews.endOfFlow]: {
    label: FormViews.endOfFlow,
    percentComplete: 90,
    trackingLabel: '18_last_step_page',
    styleOverride: { maxWidth: '100%', margin: 0, padding: 0 },
  },
};
