import { Box, Button, Heading, Text, TextArea, VStack } from 'native-base';
import styled from '@emotion/styled';
import { MobileGradientBox } from '../ui/style';
import HeaderNav from '../HeaderNav/HeaderNav';
import { MobileReviewsCarousel } from '../ui/MobileReviewsCarousel';
import { NAV_HEADER_HEIGHT_PX } from '../../constants/misc';
import { MeetingTypeId } from '../../api/types';
import { Dimensions } from 'react-native';

const ACTION_BUTTONS_HEIGHT_PX = 96;
const ACTION_BUTTONS_PADDING_PX = 16;

export const Fade = styled('div')`
  background: linear-gradient(
    to top,
    rgba(247, 245, 237, 1) 70%,
    rgba(125, 185, 232, 0) 100%
  );
  bottom: 0;
  height: 150px;
  position: absolute;
  width: 100%;
`;

type Props = {
  appointment_type: MeetingTypeId;
  feedbackTxt: string;
  isLoading: boolean;
  onFeedbackTxtChange: (feedbackTxt: string) => void;
  onSkip: () => void;
  sendFeedback: () => Promise<void>;
};

export const MobileFeedbackPage = ({
  appointment_type,
  feedbackTxt,
  isLoading,
  onFeedbackTxtChange,
  onSkip,
  sendFeedback,
}: Props) => {
  const screenHeight = Dimensions.get('window').height;
  const contentHeight = screenHeight - NAV_HEADER_HEIGHT_PX;
  const visitTypeTxt =
    appointment_type === MeetingTypeId.InitialConsultation
      ? 'provider'
      : 'coach';

  return (
    <VStack h={screenHeight} flex={1}>
      <HeaderNav hideBack />

      <MobileGradientBox>
        <VStack height={contentHeight} overflow="hidden">
          <VStack
            alignItems={'center'}
            height={
              contentHeight -
              ACTION_BUTTONS_HEIGHT_PX -
              ACTION_BUTTONS_PADDING_PX
            }
            overflow="auto"
          >
            <Box alignItems={'center'}>
              <Heading color={'secondary.500'} fontWeight={'normal'} pt={6}>
                You’re in! Let’s book your <br /> first {visitTypeTxt} visit.
              </Heading>
            </Box>

            <VStack alignItems={'center'} w={'100%'} p={8} pb={10}>
              <TextArea
                autoCompleteType={'off'}
                type="text"
                placeholder="Optional: share any context or topics you’d like to cover in your first visit."
                backgroundColor={'muted.50'}
                h={40}
                w={'100%'}
                p={5}
                rounded={'lg'}
                fontSize={'md'}
                value={feedbackTxt}
                onChange={(e: any) => onFeedbackTxtChange(e.target.value)}
              />
            </VStack>

            <VStack flex={1} w={'100%'}>
              <MobileReviewsCarousel />
            </VStack>
          </VStack>
        </VStack>

        {/* Action Buttons */}
        <Fade>
          <Box
            w={'100%'}
            position={'absolute'}
            bottom={0}
            px={6}
            height={`${ACTION_BUTTONS_HEIGHT_PX}px`}
          >
            <Button
              alignSelf={'center'}
              bgColor={'secondary.500'}
              isDisabled={!feedbackTxt}
              isLoading={isLoading}
              onPress={sendFeedback}
              pt={4}
              w={'100%'}
            >
              <Text color={'white'} fontWeight={'bold'}>
                Share With My Care Team
              </Text>
            </Button>

            <Button
              alignSelf={'center'}
              bgColor={'transparent'}
              onPress={onSkip}
              variant={'ghost'}
              w={'100%'}
            >
              <Text color={'secondary.500'} fontWeight={'bold'}>
                Skip for now
              </Text>
            </Button>
          </Box>
        </Fade>
      </MobileGradientBox>
    </VStack>
  );
};
