import { useProviderAvailability } from '../../api/scheduleAppointment/useProviderAvailability';
import { useUserPublicInfo } from '../../api/public/useUserPublicInfo';
import { useCoachAvailability } from '../../api/scheduleAppointment/useCoachAvailability';
import { CareModel } from '../../api/types';

enum AvailabilityType {
  coach = 'coach',
  provider = 'provider',
}

export const useFetchAvailability = (userId: string | undefined) => {
  const { data: userPublicData, isLoading: loadingUserPublicData } =
    useUserPublicInfo(userId);

  const { data: providerAvailability, isLoading: loadingProviderAvailability } =
    useProviderAvailability({
      care_model: userPublicData?.care_model,
      timezone: userPublicData?.timezone,
      user_id: userId,
  });

  const { data: coachAvailability, isLoading: loadingCoachAvailability } =
    useCoachAvailability({
      care_model: userPublicData?.care_model,
      coach_uuid: userPublicData?.coach_id,
      timezone: userPublicData?.timezone,
    });

  return {
    availabilityResponse: providerAvailability || coachAvailability,

    availabilityType: userPublicData?.care_model === CareModel.Collaborative
      ? AvailabilityType.provider
      : AvailabilityType.coach,

    isLoading:
      loadingUserPublicData ||
      (userPublicData?.care_model === CareModel.Collaborative && loadingProviderAvailability) ||
      (userPublicData?.care_model === CareModel.CoachOnly && loadingCoachAvailability),

    timezone: userPublicData?.timezone,
  };
};
