import { useBreakpointValue } from 'native-base';
import { Navigate } from 'react-router-dom';
import { DesktopFeedbackPage } from './DesktopFeedbackPage';
import { MobileFeedbackPage } from './MobileFeedbackPage';
import { useFeedbackPage } from './useFeedbackPage';

export const FeedbackPage = () => {
  const {
    appointment_type,
    feedbackTxt,
    isLoading,
    onFeedbackTxtChange,
    onSkip,
    redirectToHome,
    sendFeedback,
  } = useFeedbackPage();

  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });

  if (redirectToHome) return <Navigate to={'/'} replace />;

  const Page = isMobile ? MobileFeedbackPage : DesktopFeedbackPage;

  return (
    <Page
      appointment_type={appointment_type}
      feedbackTxt={feedbackTxt}
      isLoading={isLoading}
      onFeedbackTxtChange={onFeedbackTxtChange}
      onSkip={onSkip}
      sendFeedback={sendFeedback}
    />
  );
};
