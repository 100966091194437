import React from 'react';
import { Image, Link, Pressable, Flex, HStack } from 'native-base';
import { useAuth } from '../../hooks';
import { NAV_HEADER_HEIGHT_PX } from '../../constants/misc';

const backBttn = '../chevron-left-white.png';
const logo = './../FA__ayble_health_Logo_Isabelline_white.png';
const logoDark = './../FA__ayble_health_Logo_Isabelline.png';

const styles = {
  navIcon: {
    backgroundSize: 'contain',
    maxHeight: '32px',
    width: '44px',
    marginTop: '4px',
    resizeMode: 'contain',
    zIndex: 2,
  },
  logo: {
    backgroundSize: 'contain',
    maxHeight: '56px',
    resizeMode: 'contain',
  },
  backBttn: {
    width: 32,
    height: 32,
  },
};

function HeaderNav({
  handleBack = () => {},
  hideBack = false,
  pageLinks = [],
}) {
  const { isAuthenticated, signOut } = useAuth();

  const hasLinksToShow = !!pageLinks.length;
  const isDark = !hasLinksToShow;

  return (
    <Flex
      alignItems={'center'}
      backgroundColor={hasLinksToShow ? 'muted.100' : 'secondary.600'}
      flexDirection={isAuthenticated ? 'row-reverse' : 'row'}
      h={`${NAV_HEADER_HEIGHT_PX}px`}
      justifyContent={'space-between'}
      position={'relative'}
      px={'2em'}
      py={0}
      style={{ isolation: 'isolate' }}
    >
      {isAuthenticated && (
        <Link
          _text={{
            fontSize: 'xs',
            _light: {
              color: isDark ? 'light.100' : 'secondary.600',
            },
            color: isDark ? 'light.100' : 'secondary.600',
          }}
          _hover={{
            _text: {
              _light: {
                color: isDark ? 'primary.100' : 'secondary.600',
              },
              color: isDark ? 'primary.100' : 'secondary.600',
            },
          }}
          onPress={() => signOut({ returnTo: window.location.origin })}
        >
          Logout
        </Link>
      )}

      {hasLinksToShow && (
        <HStack w={300} justifyContent={'space-between'}>
          {pageLinks.map((pageLink, i) => (
            <Link
              key={`${pageLink}-${i}`}
              _text={{
                color: 'secondary.600',
                textTransform: 'none',
                textDecoration: 'none',
                fontWeight: 'medium',
              }}
              _hover={{
                _text: {
                  textDecoration: 'underline',
                  fontWeight: 'medium',
                },
              }}
              href={pageLink.href}
            >
              {pageLink.label}
            </Link>
          ))}
        </HStack>
      )}

      <Image
        size={'md'}
        style={{
          ...styles.logo,
          ...(isDark && {
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }),
        }}
        source={{
          uri: isDark ? logo : logoDark,
        }}
        alt="Ayble Health logo"
        onClick={() => window.open('https://ayblehealth.com', '_blank')}
      />

      {!hasLinksToShow && !hideBack && (
        <Pressable variant={'closer'} onPress={handleBack}>
          <Image
            zIndex="1"
            alt="back button"
            source={backBttn}
            style={styles.backBttn}
          />
        </Pressable>
      )}
    </Flex>
  );
}

export default HeaderNav;
