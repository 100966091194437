import { useMutation } from '@tanstack/react-query';
import { useApi } from '../../hooks';
import { FeedbackPayload } from '../../components/FeedbackView/types';

export const useSendFeedback = <T>() => {
  const { api } = useApi({ throwIfError: true });

  const mutation = useMutation({
    mutationFn: (feedback: FeedbackPayload<T>) => {
      return api({
        route: `/feedback/user-survey`,
        method: 'POST',
        payload: feedback,
      });
    },
  });

  return { mutation };
};
