import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSendFeedback } from '../../api/scheduleAppointment/useSendFeedback';
import { CareModel, MeetingTypeId } from '../../api/types';
import { useUserPublicInfo } from '../../api/public/useUserPublicInfo';
import { useNavigateWithParams } from '../../hooks/useNavigateWithParams';
import { TRACKING_SOLICIT_FEEDBACK } from '../OnboardingForm/constants';
import { useSegment } from '../../hooks/useSegment';
import { useReadAuthUserIdWithQueryParamsLookup } from '../../hooks/useReadAuthUserIdWithQueryParamsLookup';
import { SurveyType } from './types';

export const useFeedbackPage = () => {
  const { page: pageView } = useSegment();
  const { userId } = useReadAuthUserIdWithQueryParamsLookup();
  const {
    data: userPublicData,
    isError,
    isLoading: loadingUserPublicData,
  } = useUserPublicInfo(userId, true);
  const navigate = useNavigateWithParams();
  const { mutation } = useSendFeedback<SurveyType.visitGoals>();
  const [feedbackTxt, setFeedbackTxt] = useState('');

  useEffect(() => {
    if (!userId) return;

    pageView(TRACKING_SOLICIT_FEEDBACK);
  }, [pageView, userId]);

  const appointment_type = useMemo(
    () =>
      userPublicData?.care_model === CareModel.Collaborative
        ? MeetingTypeId.InitialConsultation
        : MeetingTypeId.InitialCoachCall,
    [userPublicData]
  );

  const onFeedbackTxtChange = useCallback(setFeedbackTxt, [setFeedbackTxt]);

  const sendFeedback = useCallback(async () => {
    if (!userId) return;

    await mutation.mutateAsync({
      survey: {
        goals: feedbackTxt,
        appointment_type,
      },
      survey_type: SurveyType.visitGoals,
      user_id: userId,
    });

    navigate('/schedule-appointment');
  }, [appointment_type, feedbackTxt, mutation, navigate, userId]);

  const onSkip = useCallback(() => {
    navigate('/schedule-appointment');
  }, [navigate]);

  return {
    appointment_type,
    feedbackTxt,
    isLoading: loadingUserPublicData || mutation.isLoading,
    onFeedbackTxtChange,
    onSkip,
    redirectToHome: !userId || isError,
    sendFeedback,
  };
};
